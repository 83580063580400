import ParteDellaMessaDataService from "../services/ParteDellaMessaDataService";
import MultipleMultiselectItem from '../model/MultipleMultiselectItem';

class ParteDellaMessaEntryPoint {

    getAll(): any {
        return ParteDellaMessaDataService.getAll()
            .then((response) => {
                var partidellamessa: MultipleMultiselectItem[] = [];
                response.data.forEach(function (value: any) {
                    partidellamessa.push(new MultipleMultiselectItem(value.nome, value.id))
                });
                //console.log("partidellamessa-->"+partidellamessa);
                return partidellamessa;
            })
            .catch((e) => {
                console.log(e);
                throw e;
            });
    }
}

export default new ParteDellaMessaEntryPoint();