import CanzoneDataService from "../services/CanzoneDataService";
import CanzoneSearchCriteria from '../model/CanzoneSearchCriteria';
import CanzoneModel from '../model/CanzoneModel';
class CanzoneEntryPoint {

    search(criteria: CanzoneSearchCriteria): any {
        var canzoni: CanzoneModel[] = [];
        return CanzoneDataService.search(criteria)
            .then((response) => {
                response.data.forEach(function (canzone: any) {
                    //console.log(value);
                    var partiDellaMessa: string = "";
                    var count: number = 1;
                    canzone.partiDellaMessaIdeali.forEach(function (parteDellaMessaIdeale: any) {
                        if (count > 1) {
                            partiDellaMessa += ", ";
                        }
                        partiDellaMessa += parteDellaMessaIdeale.nome;
                        count++;
                    });
                    var tempLiturgici: string = "";
                    var count: number = 1;
                    canzone.tempiLiturgiciIdeali.forEach(function (tempoLiturgicoIdeale: any) {
                        if (count > 1) {
                            tempLiturgici += ", ";
                        }
                        tempLiturgici += tempoLiturgicoIdeale.nome;
                        count++;
                    });
                    var paroleChiave: string = "";
                    var count: number = 1;
                    canzone.paroleChiave.forEach(function (parolaChiave: any) {
                        if (count > 1) {
                            paroleChiave += ", ";
                        }
                        paroleChiave += parolaChiave.nome;
                        count++;
                    });
                    canzoni.push(new CanzoneModel(canzone.titolo, canzone.id, canzone.idUtenteCreazione , canzone.autore, canzone.testo, partiDellaMessa, tempLiturgici, paroleChiave, canzone.visibilityPolicy, canzone.allegati));
                    //console.log(tempiliturgici);
                });
                return canzoni;
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getAll(): any {
        var canzoni: CanzoneModel[] = [];
        return CanzoneDataService.getAll()
            .then((response) => {
                response.data.forEach(function (canzone: any) {
                    //console.log(canzone);
                    var partiDellaMessa: string = "";
                    var count: number = 1;
                    canzone.partiDellaMessaIdeali.forEach(function (parteDellaMessaIdeale: any) {
                        if (count > 1) {
                            partiDellaMessa += ", ";
                        }
                        partiDellaMessa += parteDellaMessaIdeale.nome;
                        count++;
                    });
                    var tempLiturgici: string = "";
                    var count: number = 1;
                    canzone.tempiLiturgiciIdeali.forEach(function (tempoLiturgicoIdeale: any) {
                        if (count > 1) {
                            tempLiturgici += ", ";
                        }
                        tempLiturgici += tempoLiturgicoIdeale.nome;
                        count++;
                    });
                    var paroleChiave: string = "";
                    var count: number = 1;
                    canzone.paroleChiave.forEach(function (parolaChiave: any) {
                        if (count > 1) {
                            paroleChiave += ", ";
                        }
                        paroleChiave += parolaChiave.nome;
                        count++;
                    });
                    canzoni.push(new CanzoneModel(canzone.titolo, canzone.id, canzone.idUtenteCreazione, canzone.autore, canzone.testo, partiDellaMessa, tempLiturgici, paroleChiave, canzone.visibilityPolicy,canzone.allegati));
                    //console.log(tempiliturgici);
                });
                return canzoni;
                //console.log(this.Rows);
            })
            .catch((e) => {
                console.log(e);
            });
    }

}

export default new CanzoneEntryPoint();