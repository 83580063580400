import TempoLiturgicoDataService from "../services/TempoLiturgicoDataService";
import MultipleMultiselectItem from '../model/MultipleMultiselectItem';

class TempoLiturgicoEntryPoint {

    getAll(): any {
        return TempoLiturgicoDataService.getAll()
            .then((response) => {
                var tempiLiturgici: MultipleMultiselectItem[] = [];
                response.data.forEach(function (value: any) {
                    tempiLiturgici.push(new MultipleMultiselectItem(value.nome, value.id))
                });
                //console.log("tempiLiturgici-->"+tempiLiturgici);
                return tempiLiturgici;
            })
            .catch((e) => {
                console.log(e);
                throw e;
            });
    }
}

export default new TempoLiturgicoEntryPoint();