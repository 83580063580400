
























import { Component, Prop, Vue } from 'vue-property-decorator';
import CanzoneDataService from "../services/CanzoneDataService";
import FormCanzone from './FormCanzone.vue';
import ConfiguratoreLiturgiaCanzoneRow from '../model/ConfiguratoreLiturgiaCanzoneRow';
import ParteDellaMessaDataService from "../services/ParteDellaMessaDataService";
import TempoLiturgicoDataService from "../services/TempoLiturgicoDataService";
import ParolaChiaveDataService from "../services/ParolaChiaveDataService";
import MultipleMultiselectItem from '../model/MultipleMultiselectItem';
import TaggingMultiselectItem from '../model/TaggingMultiselectItem';

@Component({
    components: {
        FormCanzone
    }
})

export default class ElencoTestoConAccordiCanzoni extends Vue {
    @Prop({ required: true }) readonly canzoniSelectedListGroupByParteDellaMessa!: ConfiguratoreLiturgiaCanzoneRow[];
    // Initial data can be declared as instance properties

    private partiDellaMessaList: MultipleMultiselectItem[] = [];
    private tempiLiturgiciList: MultipleMultiselectItem[] = [];
    private paroleChiaveList: TaggingMultiselectItem[] = [];

    getAllPartiDellaMessa() {
        ParteDellaMessaDataService.getAll()
            .then((response) => {
                var partidellamessa: MultipleMultiselectItem[] = [];
                response.data.forEach(function (value: any) {
                    partidellamessa.push(new MultipleMultiselectItem(value.nome, value.id))

                });
                //console.log(partidellamessa);
                this.partiDellaMessaList = partidellamessa;
            })
            .catch((e) => {
                console.log(e);
            });
    }


    getAllTempiLiturgici() {
        TempoLiturgicoDataService.getAll()
            .then((response) => {
                var tempoLiturgico: MultipleMultiselectItem[] = [];
                response.data.forEach(function (value: any) {
                    tempoLiturgico.push(new MultipleMultiselectItem(value.nome, value.id))

                });
                //console.log(tempoLiturgico);
                this.tempiLiturgiciList = tempoLiturgico;
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getAllParoleChiave() {
        ParolaChiaveDataService.getAll()
            .then((response) => {
                var parolaChiave: TaggingMultiselectItem[] = [];
                response.data.forEach(function (value: any) {
                    parolaChiave.push(new TaggingMultiselectItem(value.nome))

                });
                //console.log(parolaChiave);
                this.paroleChiaveList = parolaChiave;
            })
            .catch((e) => {
                console.log(e);
            });
    }

    mounted() {
        this.getAllPartiDellaMessa();
        this.getAllTempiLiturgici();
        this.getAllParoleChiave();
    }
}
