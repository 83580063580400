





























































































































import { Component, Watch, Vue } from 'vue-property-decorator';
import CanzoneModel from '../model/CanzoneModel';
import CanzoneSearchCriteria from '../model/CanzoneSearchCriteria';
import MultipleMultiselectItem from '../model/MultipleMultiselectItem';
import ConfiguratoreLiturgiaCanzoneRow from '../model/ConfiguratoreLiturgiaCanzoneRow';
import { Utils } from "../utils/utils";
import { serialize } from 'object-to-formdata';

import ParteDellaMessaEntryPoint from "../EntryPoint/ParteDellaMessaEntryPoint";
import TempoLiturgicoEntryPoint from "../EntryPoint/TempoLiturgicoEntryPoint";
import ConfiguratoreLiturgiaPerParteDellaMessa from './ConfiguratoreLiturgiaPerParteDellaMessa.vue';
import FormCanzone from './FormCanzone.vue';
import ElencoTestoConAccordiCanzoni from './ElencoTestoConAccordiCanzoni.vue';
import CanzoneDataService from "../services/CanzoneDataService";
import ConfigurazioneLiturgiaDataService from "../services/ConfigurazioneLiturgiaDataService";

import { namespace } from "vuex-class";
import { forEach } from 'xregexp/types';
const Auth = namespace("Auth");

@Component({
    components: {
        ConfiguratoreLiturgiaPerParteDellaMessa,
        FormCanzone,
        ElencoTestoConAccordiCanzoni
    }
})
export default class ConfiguratoreLiturgia extends Vue {
    // Initial data can be declared as instance properties
    pageTitle: string = 'Configuratore Liturgia';

    @Auth.State("user")
    private currentUser!: any;

    @Auth.Action
    public signOut!: () => void;

    private allSelected: boolean = false;
    private indeterminate: boolean = false;
    private maxNumberOfResult: number = 3;
    private partiDellaMessaList: MultipleMultiselectItem[] = [];
    private partiDellaMessaListSelected: any = [];
    private canzoniSelectedListGroupByParteDellaMessa: any = [];
    private modalContainerId: string = "body-modal";
    private hascanzonidoppie: boolean = false;

    private feedback: string = "";
    private feedbackshow: boolean = false;
    private feedbackAlertType: string = "";

    get IsLogged() {
        return this.currentUser != null;
    }

    get disabledForm() {
        return !this.IsLogged;
    }

    get dateNow() {
        var today = new Date();
        return today;
    }

    get canModificaConfigurazione() {
        return !this.disabledForm && (this.dataLiturgia == "" || new Date((new Date(this.dataLiturgia)).valueOf() + 1000 * 3600 * 24) > this.dateNow);
    }

    get partiDellaMessaListComputed() {
        if (this.partiDellaMessaList != null && this.partiDellaMessaList.length > 0)
            return this.partiDellaMessaList;
        else {
            return [];
        }

    }
    set partiDellaMessaListComputed(newValue: MultipleMultiselectItem[]) {
        this.partiDellaMessaList = newValue;
    }

    checkifHasCanzonidoppie() {
        //console.log(this.getCanzoniSelezionate());
        if (this.getCanzoniSelezionate() != null && this.getCanzoniSelezionate().length > 0)
            this.hascanzonidoppie = this.hasDuplicates(this.getCanzoniSelezionate().map(element => element.canzone.id));
    }

    hasDuplicates(array: any) {
        console.log(array);
        return (new Set(array)).size !== array.length;
    }

    private tempiLiturgiciOptionList: MultipleMultiselectItem[] = [];
    private tempoLiturgicoSelected: MultipleMultiselectItem = new MultipleMultiselectItem("Seleziona...", -1);

    private textToSearch: string[] = [];
    //getParteDellaMessaIngressoId() {
    //    console.log("getParteDellaMessaIngressoId");
    //    //this.popolaAllPartiDellaMessa();
    //    console.log("this.partiDellaMessaList-->" + this.partiDellaMessaList);
    //    var ingresso = this.getParteDellaMessaIngresso();
    //    console.log("getParteDellaMessaIngressoId-->"+ingresso);
    //    return ingresso?.id;
    //}

    //getParteDellaMessaIngresso() {

    //    var ingresso = this.partiDellaMessaList.find(({ nome }) => nome == 'Ingresso');

    //    return ingresso;
    //}

    popolaAllPartiDellaMessa() {
        if (this.partiDellaMessaList == null || this.partiDellaMessaList.length == 0) {
            //console.log("popolaAllPartiDellaMessa if");
            this.partiDellaMessaList = ParteDellaMessaEntryPoint.getAll().then((data: any) => {
                //console.log("data-->" + data);
                this.partiDellaMessaList = data;
                this.partiDellaMessaListSelected = this.partiDellaMessaList.map(p => p.id);
            });
        }
        //console.log("popolaAllPartiDellaMessa-->")
        //console.log( this.partiDellaMessaList);
    }


    popolaAllTempiLiturgici() {
        if (this.tempiLiturgiciOptionList == null || this.tempiLiturgiciOptionList.length == 0) {
            //console.log("popolaAllTempiLiturgici if");
            this.tempiLiturgiciOptionList = TempoLiturgicoEntryPoint.getAll().then((data: any) => {
                //console.log("data-->" + data);
                this.tempiLiturgiciOptionList = data;
                this.tempiLiturgiciOptionList.push(new MultipleMultiselectItem("Seleziona...", -1));
            });
        }

        //console.log("popolaAllPartiDellaMessa-->" + this.partiDellaMessaList);
    }

    //changedTempoLiturgicoSelection(tempoLiturgicoSelezionato: MultipleMultiselectItem) {
    //    this.tempoLiturgicoSelected = tempoLiturgicoSelezionato;
    //    console.log(tempoLiturgicoSelezionato);
    //}

    SearchCanzoni() {
        //console.log(this.tempoLiturgicoSelected);
        for (let item of this.partiDellaMessaList) {
            var nome = "pdm-" + item.nome;
            (this.$refs[nome] as any)[0].searchcanzoni();
        }
    }

    mostraSpartiti() {
        var canzoniSelectedList = this.getCanzoniSelezionate();
        this.canzoniSelectedListGroupByParteDellaMessa = Utils.groupBy(canzoniSelectedList, 'parteDellaMessaNome');
        //console.log(this.canzoniSelectedListGroupByParteDellaMessa);
        this.showModaleSpartiti();
    }

    showModaleSpartiti() {
        (this.$refs['modaleSpartiti'] as any).show()
        //this.$bvModal.show('modaleDelete');
    };

    getCanzoniSelezionate() {
        var canzoniSelectedList: ConfiguratoreLiturgiaCanzoneRow[] = [];
        for (let item of this.partiDellaMessaList) {
            var nome = "pdm-" + item.nome;
            //console.log(nome);
            var canzoniSelezionate = (this.$refs[nome] as any)[0].getCanzoniSelezionate();
            if (canzoniSelezionate.length > 0) {
                console.log(canzoniSelezionate);
                canzoniSelectedList = canzoniSelectedList.concat(canzoniSelezionate);
            }
        }
        console.log(canzoniSelectedList);
        return canzoniSelectedList;

    }

    setCanzoniSelezionate() {
        //console.log(this.configurazioneLiturgia);
        if (this.configurazioneLiturgia != null && this.configurazioneLiturgia.configurazioneLiturgiaCanzoni.length > 0) {
            for (let item of this.configurazioneLiturgia.configurazioneLiturgiaCanzoni) {                   
                var nome = "pdm-" + item.parteDellaMessa.nome;                   
                var configuratoreLiturgiaRow = new ConfiguratoreLiturgiaCanzoneRow(new CanzoneModel(item.canzone.titolo, item.canzone.id, item.canzone.idUtenteCreazione, item.canzone.autore, item.canzone.testo, item.partiDellaMessa, item.tempLiturgici, item.paroleChiave, item.canzone.visibilityPolicy, item.canzone.allegati), new MultipleMultiselectItem(item.parteDellaMessa.nome, item.parteDellaMessa.id, item.parteDellaMessa.ordinamento));
                configuratoreLiturgiaRow.numeroParoleTrovate = item.paroleTrovate.length;
                configuratoreLiturgiaRow.paroleTrovate = item.paroleTrovate;
                configuratoreLiturgiaRow.selected = true;
                //console.log("pippo configuratoreLiturgiaRow");
                //console.log(configuratoreLiturgiaRow);
                (this.$refs[nome] as any)[0].setCanzoneSelezionata(configuratoreLiturgiaRow);
            }
        }
    }

    toggleAll(checked: boolean) {
        //console.log("toggleAll");
        //console.log(checked);
        this.partiDellaMessaListSelected = checked ? this.partiDellaMessaList.map(p => p.id) : [];
        //console.log(this.partiDellaMessaList);

    }

    openPopupSaveConfigurazione() {
        this.checkifHasCanzonidoppie();
        this.showModaleSaveConfigurazione();
    }
    showModaleSaveConfigurazione() {
        (this.$refs['modaleSaveConfigurazione'] as any).show()
    };
    hideModaleSaveConfigurazione() {
        (this.$refs['modaleSaveConfigurazione'] as any).hide()
    };
    private configurazioneLiturgia: any;
    private nomeConfigurazione: string = "";
    private dataLiturgia: any = "";
    private visibilitaSelected: any = 1;
    private visibilitaOptionList: any = [{ value: 1, text: 'Privata' }, { value: 2, text: 'Pubblica' }];

    //private _visibilitaSelected: any = 1;
    //get visibilitaSelected() {
    //    console.log("visibilitaSelected");           
    //    if (this._visibilitaSelected == null)
    //        this._visibilitaSelected = this.configurazioneLiturgia != null && typeof this.configurazioneLiturgia != "undefined" && this.configurazioneLiturgia.visibilityPolicy != null && typeof this.configurazioneLiturgia.visibilityPolicy != "undefined" ? this.configurazioneLiturgia.visibilityPolicy : 1;
    //    return this._visibilitaSelected;
    //}
    //set visibilitaSelected(value: any) {
    //    console.log("set visibility");
    //    this._visibilitaSelected = value;
    //}

    saveConfigurazione() {
        //console.log('saveConfigurazione');
        //console.log('saveConfigurazione');
        const options = {
            /**
             * include array indices in FormData keys
             * defaults to false
             */
            indices: true,

            /**
             * treat null values like undefined values and ignore them
             * defaults to false
             */
            nullsAsUndefineds: false,

            /**
             * convert true or false to 1 or 0 respectively
             * defaults to false
             */
            booleansAsIntegers: false,

            /**
             * store arrays even if they're empty
             * defaults to false
             */
            allowEmptyArrays: true,
        };
        //console.log(this.configurazioneLiturgia);

        if (typeof this.configurazioneLiturgia == "undefined" || this.configurazioneLiturgia?.id == null || this.configurazioneLiturgia.id == 0) {
            this.configurazioneLiturgia = {
                nome: this.nomeConfigurazione,
                dataliturgia: this.dataLiturgia,
                visibilityPolicy: this.visibilitaSelected,
                configurazioneliturgiacanzoni: this.getCanzoniSelezionate()
            };
            var form_data = serialize(
                this.configurazioneLiturgia,
                options,
            );

            console.log('ConfigurazioneLiturgiaDataService.create');
            ConfigurazioneLiturgiaDataService.create(form_data)
                .then((response) => {
                    //this.canzone.id = response.data.id;
                    //this.feedbackItem = new FeedbackItem(true, true);
                    this.feedbackshow = true;
                    this.feedbackAlertType = "success";
                    this.feedback = "Configurazione " + this.nomeConfigurazione + " creata con successo";

                    this.cleancSaveConfigurazioneSEttings();
                },
                    (error) => {
                        this.feedbackshow = true;
                        this.feedbackAlertType = "danger";
                        this.feedback = (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();
                        console.log(error.response);
                        if (error.response.status === 401) {
                            this.signOut();
                            this.$router.push("/login");
                        }
                    })
                .catch((e) => {

                    this.feedbackshow = true;
                    this.feedbackAlertType = "danger";
                    this.feedback = e.message;
                    console.log(e);

                    if (e.response.status === 401) {
                        this.signOut();
                        this.$router.push("/login");
                    }
                });
        }
        else {
            this.configurazioneLiturgia.nome = this.nomeConfigurazione;
            this.configurazioneLiturgia.autore = this.dataLiturgia;
            this.configurazioneLiturgia.visibilityPolicy = this.visibilitaSelected;
            this.configurazioneLiturgia.configurazioneLiturgiaCanzoni = this.getCanzoniSelezionate();

            console.log("this.configurazioneLiturgia -->");
            console.log(this.configurazioneLiturgia);

            console.log("this.configurazioneLiturgia.configurazioneliturgiacanzoni -->");
            console.log(this.configurazioneLiturgia.configurazioneliturgiacanzoni);

            var form_data = serialize(
                this.configurazioneLiturgia,
                options,
            );
            console.log("update -->");
            console.log(form_data);

            ConfigurazioneLiturgiaDataService.update(form_data)
                .then((response) => {
                    //this.feedbackItem = new FeedbackItem(true, true);
                    this.feedbackshow = true;
                    this.feedbackAlertType = "success";
                    this.feedback = "Configurazione " + this.nomeConfigurazione + " modificata con successo";
                    //console.log(response.data);
                    //this.message = "The tutorial was updated successfully!";
                },
                    (error) => {
                        this.feedbackshow = true;
                        this.feedbackAlertType = "danger";
                        this.feedback = (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();

                        if (error.response.status === 401) {
                            this.signOut();
                            this.$router.push("/login");
                        }
                    })
                .catch((e) => {
                    this.feedbackshow = true;
                    this.feedbackAlertType = "danger";
                    this.feedback = e.message;
                    console.log(e);

                    if (e.response.status === 401) {
                        this.signOut();
                        this.$router.push("/login");
                    }
                });
        }
    }

    cleancSaveConfigurazioneSEttings() {
        this.configurazioneLiturgia = null;
        this.nomeConfigurazione = "";
        this.dataLiturgia = "";
    }

    getConfigurazioneLiturgia(id: string, isDuplicato: boolean) {
        ConfigurazioneLiturgiaDataService.get(id)
            .then((response) => {
                //console.log(response.data);
                this.configurazioneLiturgia = response.data;                   
                this.setCanzoniSelezionate();
                if (isDuplicato) {
                    this.configurazioneLiturgia.id = 0;
                    this.configurazioneLiturgia.dataLiturgia = "";
                    this.configurazioneLiturgia.nome = "";
                    this.configurazioneLiturgia.visibilityPolicy = 1;
                }
                else {
                    this.nomeConfigurazione = this.configurazioneLiturgia.nome;
                    this.dataLiturgia = this.configurazioneLiturgia.dataLiturgia;
                    this.visibilitaSelected = this.configurazioneLiturgia.visibilityPolicy;
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }


    @Watch('partiDellaMessaListSelected')
    onPartiDellaMessaListSelectedChanged(newValue: any, oldValue: any) {
        if (newValue.length === 0) {
            this.indeterminate = false
            this.allSelected = false
        } else if (newValue.length === this.partiDellaMessaList.length) {
            this.indeterminate = false
            this.allSelected = true
        } else {
            this.indeterminate = true
            this.allSelected = false
        }
    }

    mounted() {
        this.popolaAllPartiDellaMessa();
        this.popolaAllTempiLiturgici();
        if (this.$route.params.id != null) {
            this.getConfigurazioneLiturgia(this.$route.params.id, false);                
        }
        if (this.$route.query.duplica === 'true' && this.$route.query.idDuplica.length > 0) {
            this.getConfigurazioneLiturgia(this.$route.query.idDuplica.toString(), true);
        }
      
       
        //this.SearchCanzoni();
        //this.SearchCanzoni();
        //(async () => {
        //    await this.popolaAllPartiDellaMessa();
        //})();
        //(async () => {
        //    await this.SearchCanzoni();;
        //})();

        // Set the initial number of items
    }
}
