




































import { Component, Prop, Vue } from 'vue-property-decorator';
import CanzoneModel from '../model/CanzoneModel';
import CanzoneSearchCriteria from '../model/CanzoneSearchCriteria';
import MultipleMultiselectItem from '../model/MultipleMultiselectItem';
import CanzoneEntryPoint from "../EntryPoint/CanzoneEntryPoint";

import CanzoneDataService from "../services/CanzoneDataService";
import { namespace } from "vuex-class";
import { forEach } from 'xregexp/types';
import ConfiguratoreLiturgiaCanzoneRow from '../model/ConfiguratoreLiturgiaCanzoneRow';
const Auth = namespace("Auth");

@Component({
    components: {

    }
})
export default class ConfiguratoreLiturgiaPerParteDellaMessa extends Vue {
    @Prop({ required: true }) readonly textToSearch!: string[];
    @Prop({ required: true }) readonly parteDellaMessa!: MultipleMultiselectItem;
    @Prop({ required: true }) readonly tempoLiturgico!: MultipleMultiselectItem;
    @Prop({ required: false, default: 3 }) maxNumberOfResult!: number;
    // Initial data can be declared as instance properties

    private canzoniFilterList: ConfiguratoreLiturgiaCanzoneRow[] = [];
    private numeroCanzoniSlezionate: number = 0;
    private canzoniSelectedList: ConfiguratoreLiturgiaCanzoneRow[] = [];

    public searchcanzoni() {
        this.canzoniFilterList = [];
        var _canzoniFilterList: ConfiguratoreLiturgiaCanzoneRow[] = this.canzoniSelectedList.slice();
        console.log(this.textToSearch);
        console.log(this.canzoniSelectedList);
        var criteria = new CanzoneSearchCriteria();
        //console.log(this.parteDellaMessa);
        criteria.parteDellaMessaID = this.parteDellaMessa.id;
        criteria.searchInAllTextLike = this.textToSearch.join(" ");
        //console.log("this.tempoLiturgico-->" + this.tempoLiturgico.nome);
        if (this.tempoLiturgico != null && this.tempoLiturgico.id > 0)
            criteria.tempoLiturgicoID = this.tempoLiturgico.id;

        //console.log("maxNumberOfResult-->" + this.maxNumberOfResult)
        //console.log("criteria.tempoLiturgicoID-->"+criteria.tempoLiturgicoID);
        
        CanzoneEntryPoint.search(criteria).then((data: any) => {
            //console.log(data);

            data.forEach((canzoneRow: CanzoneModel) => {
                var proposteMessaRow = new ConfiguratoreLiturgiaCanzoneRow(canzoneRow, this.parteDellaMessa);
                if (_canzoniFilterList.length == 0 || !_canzoniFilterList.some(element => element.canzone.id == proposteMessaRow.canzone.id)) {
                    _canzoniFilterList.push(proposteMessaRow);
                }
            });

            _canzoniFilterList.forEach(element => {
                if (this.textToSearch.length > 0) {
                    this.textToSearch.forEach((parola) => {
                        //console.log("element");
                        console.log(element.canzone);
                        if (!element.paroleTrovate.includes(parola) &&
                            ((element.canzone.titolo != null && element.canzone.titolo.toLowerCase().includes(parola.toLowerCase()))
                            || (element.canzone.autore != null && element.canzone.autore.toLowerCase().includes(parola.toLowerCase()))
                                /*|| proposteMessaRow.canzone.tempiLiturgici.toLowerCase().includes(parola.toLowerCase())*/
                            || (element.canzone.paroleChiave != null && element.canzone.paroleChiave.toLowerCase().includes(parola.toLowerCase()))
                            || (element.canzone.testo != null && element.canzone.testo.toLowerCase().includes(parola.toLowerCase())))
                        ) {
                            //console.log("nell if");
                            element.numeroParoleTrovate++
                            element.paroleTrovate.push(parola);
                        }
                    });
                }
            });

            //console.log("this.partedellamessa-->" + this.parteDellaMessa.nome);
            //console.log("length-->" + _canzoniFilterList.length);

            if (_canzoniFilterList.length < this.maxNumberOfResult) {
                criteria.searchInAllTextLike = "";
                criteria.maxResult = this.maxNumberOfResult;
                CanzoneEntryPoint.search(criteria).then((data: any) => {
                    data.forEach((canzoneRow: CanzoneModel) => {
                        if (!_canzoniFilterList.some(c => c.canzone.id == canzoneRow.id)) {
                            //console.log("canzoneRow:");
                            //console.log(canzoneRow);
                            var proposteMessaRow = new ConfiguratoreLiturgiaCanzoneRow(canzoneRow, this.parteDellaMessa);
                            _canzoniFilterList.push(proposteMessaRow);
                        }
                    });

                    this.ordinaetagliarisultati(_canzoniFilterList)
                });
            }
            else {
                this.ordinaetagliarisultati(_canzoniFilterList);
            }

           
        });
       

   
    }

    public getCanzoniSelezionate() {
        //console.log(this.canzoniSelectedList);
        return this.canzoniSelectedList;
    }

    public setCanzoneSelezionata(configuratoreLiturgiaRow: ConfiguratoreLiturgiaCanzoneRow) {
        this.canzoniSelectedList.push(configuratoreLiturgiaRow);
        this.canzoniFilterList.push(configuratoreLiturgiaRow);
    }

    ordinaetagliarisultati(_canzoniFilterList: ConfiguratoreLiturgiaCanzoneRow[]) {
        //console.log("_canzoniFilterList:");
        //console.log(_canzoniFilterList);
        var _canzoniFilterListSorted = _canzoniFilterList.sort((n1, n2) => {
            if (n1.numeroParoleTrovate > n2.numeroParoleTrovate) {
                return -1;
            }

            if (n1.numeroParoleTrovate < n2.numeroParoleTrovate) {
                return 1;
            }

            return 0;
        });

        //console.log("_canzoniFilterListSorted:");
        //console.log(_canzoniFilterListSorted.length);

        for (var i = 0; i < _canzoniFilterListSorted.length; i++) {
            if (i < this.maxNumberOfResult - this.numeroCanzoniSlezionate) {
                this.canzoniFilterList.push(_canzoniFilterListSorted[i]);
            }
        }
        //console.log("canzoniFilterList:");
        //console.log(this.canzoniFilterList);
    }

    selectItem(item: ConfiguratoreLiturgiaCanzoneRow) {
        //this.canzoniFilterList.filter((element) => element.canzone.id != item.canzone.id ).forEach((element) => element.selected = false );
        item.selected = !item.selected;
        this.canzoniSelectedList = this.canzoniFilterList.filter((element) => element.selected == true);
    }

    mounted() {

    }
}
